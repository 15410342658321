export const required = (value: any) => (value ? undefined : 'Required input')

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = (min: number) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or higher` : undefined

export const minLength8 = minLength(8)

export const email = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Geçersiz mail adresi' : undefined
