import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { get } from 'actions/boards'
import Select, { Option, OptGroup } from 'components/Select'
import Board from 'types/Board'
import Role from 'types/Role'
import { BoardsState } from 'store/reducers/boardsReducer'
import { AppState } from 'store'

export interface Props {
  defaultValue?: number
}

class RoleSelect extends Component<BoardsState & Props> {
  componentDidMount = () => {
    const { initialized } = this.props
    if (!initialized) get()
  }

  render = () => {
    const { data, fetched } = this.props
    return (
      <Field
        name="role.id"
        label="Görev"
        placeholder="Seçiniz"
        component={Select}
        type="select"
        style={{ width: '100%' }}
        loading={!fetched}
        disabled={!fetched}
        defaultValue={14}
      >
        {data.map((board: Board) => (
          <OptGroup key={board.id} label={board.name}>
            {board.roles.map((role: Role) => (
              <Option value={role.id} key={role.id}>
                {role.name}
              </Option>
            ))}
          </OptGroup>
        ))}
        <OptGroup key={'üye'} label={'Üye'}>
          <Option value={14} key={'üye'}>
            Üye
          </Option>
        </OptGroup>
      </Field>
    )
  }
}

export default connect((state: AppState): BoardsState => state.boards)(RoleSelect)
