import React from 'react'
import makeField from 'utils/makeField'
import MaskedInput from 'react-text-mask'

const DateInput = ({ value, onChange }: { value: any; onChange: Function }) => {
  return (
    <MaskedInput
      value={value}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
      className="ant-input"
      onChange={param => onChange(param)}
      placeholder="YYYY-AA-GG"
    />
  )
}

export default makeField(DateInput)
