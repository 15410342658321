import User from 'types/User'
import { Reducer, AnyAction } from 'redux'

export interface AuthState {
  auth: boolean
  user: User | null
}

const initialState: AuthState = {
  auth: false,
  user: null
}

const reducer: Reducer<AuthState, AnyAction> = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_USER':
      state = {
        ...state,
        user: { ...state.user, ...action.payload },
        auth: action.payload ? true : false
      }
      break
    case 'REMOVE_USER':
      state = initialState
      break
    default:
  }
  return state
}

export default reducer
