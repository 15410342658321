import React from 'react'
import { Layout } from 'antd'

const { Content } = Layout

export default ({ children }: { children: React.ReactNode }) => (
  <Content
    style={{
      padding: 24,
      minHeight: 280,
      display: 'flex'
    }}
  >
    <div
      style={{
        flex: 1,
        padding: 24,
        background: '#fff'
      }}
    >
      {children}
    </div>
  </Content>
)
