/* eslint-disable eqeqeq */
import call from '../utils/call'
import { dispatch } from 'store'
import { diff } from 'deep-object-diff'
import flat from 'flat'
import { AxiosResponse } from 'axios'
import { allFields } from 'queries/userQuery'

/**
 * Get current users.
 */
export const get = async () => {
  try {
    dispatch({ type: 'LOAD_USERS_REQUEST' })
    const response: AxiosResponse = await call.query(
      `
        {
          users {${allFields}}
        }`
    )
    dispatch({ type: 'LOAD_USERS_SUCCESS', payload: response.data.users })
  } catch (error) {
    dispatch({ type: 'LOAD_USERS_FAILURE' })
  }
}

/**
 * Set user to view.
 * @param {*} id
 */
export const setViewUserData = (id?: number) => {
  if (id) {
    dispatch({ type: 'SHOW_VIEW_USER_DATA', payload: id })
    return
  }
  dispatch({ type: 'HIDE_VIEW_USER_DATA' })
}

/**
 * Reset user to view.
 * @param {*} visible
 */
export const resetViewUserData = (visible: boolean) => {
  if (!visible) dispatch({ type: 'RESET_VIEW_USER_DATA' })
}

/**
 * Set user to add.
 * @param {*} values
 */
export const setAddUserData = (values?: any) => {
  if (values) {
    dispatch({ type: 'SHOW_ADD_USER_DATA', payload: values })
    return
  }
  dispatch({ type: 'HIDE_ADD_USER_DATA' })
}

/**
 * Reset user to add.
 * @param {*} visible
 */
export const resetAddUserData = (visible: boolean) => {
  if (!visible) dispatch({ type: 'RESET_ADD_USER_DATA' })
}

/**
 * Set user to edit.
 * @param {*} id
 */
export const setEditUserData = (id?: number) => {
  if (id) {
    dispatch({ type: 'SHOW_EDIT_USER_DATA', payload: id })
    return
  }
  dispatch({ type: 'HIDE_EDIT_USER_DATA' })
}

/**
 * Reset user to edit.
 * @param {*} visible
 */
export const resetEditUserData = (visible: boolean) => {
  if (!visible) dispatch({ type: 'RESET_EDIT_USER_DATA' })
}

export const addUserSubmit = async (values: any, dispatch: any, props: any) => {
  let differences: { [x: string]: any } = diff(props.selectedAddUserData, values)
  differences = flat(differences, {
    delimiter: '_'
  })
  console.warn(props.selectedAddUserData, differences)
  try {
    await call.mutation({
      name: 'userCreate',
      data: props.selectedAddUserData.id
        ? { id: props.selectedAddUserData.id, ...differences }
        : { phone_number: props.selectedAddUserData.phone_number, ...differences },
      response:
        'id, avatar, firstname, lastname, email, board_email, phone_number, student_number, blood, sex, department { id, name }, role { id, name }'
    })
  } catch (error) {
    // error
  }
}

export const updateUserSubmit = async (values: any, dispatch: any, props: any) => {
  let differences: { [x: string]: any } = diff(props.data.find((e: any) => e.id == props.selectedEditUserData), values)
  differences = flat(differences, {
    delimiter: '_'
  })
  try {
    await call.mutation({
      name: 'userUpdate',
      data: { id: props.selectedEditUserData, ...differences },
      response:
        'id, avatar, firstname, lastname, email, board_email, phone_number, student_number, blood, sex, department { id, name }, role { id, name }'
    })
    /*
    await call.post(`/api/users/update/${props.selectedEditUserData}`, form, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })*/
  } catch (error) {
    // error
  }
}

export const updateUserSubmitSuccess = () => {
  setEditUserData()
}

export const addUserSubmitSuccess = () => {
  setAddUserData()
}

export const toggleAddUserModal = (value: any) => {
  dispatch({ type: 'TOGGLE_ADD_USER_MODAL', payload: value })
}
