import React, { Component } from 'react'
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Splash from 'screens/Splash'
import Auth from 'screens/Auth'
import NotAuth from 'screens/NotAuth'
import store, { history } from 'store'
import { initAuth } from 'actions/auth'
import ql from 'utils/ql'
import 'style.sass'

@(connect((state: any) => state.auth) as any)
class App extends Component<any> {
  componentDidMount = () => {
    initAuth()
  }

  render = () => {
    const { auth } = this.props
    if (auth === null) {
      return <Splash />
    }
    if (auth) {
      return <Auth />
    }
    return <NotAuth />
  }
}

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
)

export default Root
