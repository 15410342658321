import React from 'react'
import { Spin } from 'antd'

const Splash = () => (
  <main id="splash">
    <Spin />
  </main>
)

export default Splash
