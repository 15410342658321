import React, { Component } from 'react'
import Content from 'components/Content'
import Avatar from 'components/Avatar'
import { Row, Col, Typography, Tabs, Divider } from 'antd'
import { connect } from 'react-redux'
import DescriptionItem from 'components/DescriptionItem'
import { AuthState } from 'store/reducers/authReducer'
import { AppState } from 'store'
import { getFullName, getRoleName, getDepartmentName } from 'selectors/userSelectors'

const { TabPane } = Tabs
const { Title, Text } = Typography

class Profile extends Component<any> {
  render = () => {
    const { user } = this.props
    const { email, phone_number, student_number, sex, emergency_phone_number, blood, birthday, avatar } = user
    return (
      <section id="profile">
        <Content>
          <div className="banner" />
          <Row>
            <Col className="info" span={6}>
              <Avatar className="avatar" src={avatar} />
              <Title level={3}>{getFullName(user)}</Title>
              <Text>{getRoleName(user)}</Text>
            </Col>
            <Col span={18}>
              <Tabs animated={false} defaultActiveKey="1">
                <TabPane tab="Bilgiler" key="1">
                  <p className="description-group">Kişisel Bilgiler</p>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem title="Statü" content={getRoleName(user)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <DescriptionItem title="Bölüm" content={getDepartmentName(user)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem title="Öğrenci No" content={student_number} />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem title="Kan Grubu" content={blood} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem title="Doğum Tarihi" content={birthday} />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem title="Cinsiyet" content={sex === 0 ? 'Erkek' : 'Kadın'} />
                    </Col>
                  </Row>
                  <Divider />
                  <p className="description-group">İletişim</p>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem title="E-Posta" content={email} />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem title="Telefon" content={phone_number} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem title="Acil Durum Telefon" content={emergency_phone_number} />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Content>
      </section>
    )
  }
}

export default connect((state: AppState): AuthState => state.auth)(Profile)
