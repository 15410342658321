import React, { Component } from 'react'
import { Tabs, Button, Form, Icon } from 'antd'
import Input from 'components/Input'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues } from 'redux-form'
import Avatar from 'components/Avatar'
import { required, email } from 'utils/validation'
import FileInput from 'components/FileInput'
import DepartmentSelect from 'components/DepartmentSelect'
import RoleSelect from 'components/RoleSelect'
import bloods from 'constants/bloods'
import DateInput from 'components/DateInput'
import Select, { Option } from 'components/Select'
import { get } from 'lodash'
import { setAddUserData, addUserSubmit, addUserSubmitSuccess } from 'actions/users'
import Drawer from 'components/Drawer'
import { AppState } from 'store'

const { TabPane } = Tabs

@(connect((state: AppState) => {
  const value = getFormValues('addUser')(state)
  return {
    user: value,
    ...state.users
  }
}) as any)
@(reduxForm({
  form: 'addUser',
  onSubmit: addUserSubmit,
  onSubmitSuccess: addUserSubmitSuccess
}) as any)
class AddProfile extends Component<any> {
  componentWillReceiveProps = nextProps => {
    if (this.props.selectedAddUserData !== nextProps.selectedAddUserData) {
      const { initialize } = nextProps
      initialize(nextProps.selectedAddUserData)
    }
  }

  render = () => {
    const { submitting, handleSubmit, invalid, user, afterVisibleChange, selectedAddUserVisible, onClose } = this.props
    let avatar = get(user, 'avatar')
    return (
      <Drawer
        width={340}
        title="Profil"
        destroyOnClose
        afterVisibleChange={afterVisibleChange}
        placement="right"
        onClose={onClose}
        visible={selectedAddUserVisible}
        footer={
          <>
            <Button onClick={() => setAddUserData()} style={{ marginRight: 8 }}>
              İptal
            </Button>
            <Button onClick={handleSubmit} loading={submitting} disabled={invalid || submitting} type="primary">
              Kaydet
            </Button>
          </>
        }
      >
        <Form>
          <div className="avatar-wrapper">
            <Avatar size={110} border="true" borderColor="white" src={avatar ? avatar.base64 || avatar : ''}>
              <div className={'add-avatar' + (avatar ? ' uploaded' : '')}>
                <Icon type={'plus'} style={{ fontSize: '2rem' }} />
                <Field name="avatar" component={FileInput} />
              </div>
            </Avatar>
          </div>
          <Tabs animated={false} defaultActiveKey="1">
            <TabPane tab="Kişisel Bilgiler" key="1">
              <RoleSelect defaultValue={14} />

              <Field label="Öğrenci Numarası" name="student_number" component={Input} type="text" />

              <Field label="İsim" name="firstname" component={Input} validate={[required]} type="text" />

              <Field label="Soyisim" name="lastname" component={Input} validate={[required]} type="text" />

              <Field label="E-posta" name="email" component={Input} validate={[required, email]} type="email" />
            </TabPane>
            <TabPane tab="Ekstra Bilgiler" key="2">
              <Field label="Telefon" name="phone_number" component={Input} type="tel" />

              <Field label="Acil Durum Telefon" name="emergency_phone_number" component={Input} type="tel" />

              <DepartmentSelect />

              <Field name="blood" label="Kan Grubu" placeholder="Seçiniz" component={Select}>
                {bloods.map(blood => (
                  <Option key={blood}>{blood}</Option>
                ))}
              </Field>

              <Field label="Doğum Tarihi" name="birthday" component={DateInput} />
            </TabPane>
          </Tabs>
        </Form>
      </Drawer>
    )
  }
}

export default AddProfile
