import React, { Component } from 'react'
import { Row, Col, Divider, Avatar } from 'antd'
import { connect } from 'react-redux'
import Drawer from 'components/Drawer'
import DescriptionItem from 'components/DescriptionItem'
import { getFullName, getRoleName, getDepartmentName } from 'selectors/userSelectors'

@(connect((state: any) => state.users) as any)
class ViewProfile extends Component<any> {
  render = () => {
    const { selectedViewUserData, selectedViewUserVisible, afterVisibleChange, data, onClose } = this.props
    const user = data.find(user => user.id === selectedViewUserData) || []
    const { email, phone_number, student_number, sex, emergency_phone_number, blood, birthday, avatar } = user
    return (
      <Drawer
        width={640}
        title="Profil"
        destroyOnClose
        afterVisibleChange={afterVisibleChange}
        placement="right"
        onClose={onClose}
        visible={selectedViewUserVisible}
      >
        <p className="description-group">
          <Avatar src={avatar} shape="square" icon="user" style={{ marginRight: 5 }} />
          {getFullName(user)}
        </p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Statü" content={getRoleName(user)} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Bölüm" content={getDepartmentName(user)} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Öğrenci No" content={student_number} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Kan Grubu" content={blood} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Doğum Tarihi" content={birthday} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Cinsiyet" content={sex === 0 ? 'Erkek' : 'Kadın'} />
          </Col>
        </Row>
        <Divider />
        <p className="description-group">İletişim</p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="E-Posta" content={email} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Telefon" content={phone_number} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Acil Durum Telefon" content={emergency_phone_number} />
          </Col>
        </Row>
      </Drawer>
    )
  }
}

export default ViewProfile
