import React, { Component } from 'react'

interface Props {
  className?: string
  size?: number
  borderColor?: string
  src: any
  border?: string
}

class Avatar extends Component<Props> {
  render() {
    const { size, borderColor, src, border, children, ...rest } = this.props
    return (
      <div
        style={{
          borderColor: borderColor
        }}
        {...rest}
      >
        {src && <img src={src} alt="User" />}
        {children}
      </div>
    )
  }
}

export default Avatar
