import React, { Component } from 'react'
import { Row } from 'antd'

class Dashboard extends Component {
  render = () => {
    return (
      <section id="dashboard">
        <Row>boş</Row>
      </section>
    )
  }
}

export default Dashboard
