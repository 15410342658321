import React from 'react'
import { Drawer } from 'antd'

interface Params {
  [key: string]: any
}

export default ({ children, footer, ...props }: Params) => (
  <Drawer {...props}>
    <div
      className="drawer-content"
      style={{
        maxHeight: footer ? 'calc(100vh - 110px)' : 'calc(100vh - 55px)'
      }}
    >
      {children}
    </div>
    {footer && (
      <div
        style={{
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right'
        }}
      >
        {footer}
      </div>
    )}
  </Drawer>
)
