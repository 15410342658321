export const allFields = `
  id,
  avatar,
  firstname,
  lastname,
  email,
  board_email,
  phone_number,
  student_number,
  blood,
  sex,
  department {
    id,
    name
  }
  role {
    id,
    name
  }
`
