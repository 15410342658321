import React, { Component } from 'react'
import { Button, Form, Icon } from 'antd'
import Input from 'components/Input'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, InjectedFormProps } from 'redux-form'
import Avatar from 'components/Avatar'
import { required, email } from 'utils/validation'
import FileInput from 'components/FileInput'
import DepartmentSelect from 'components/DepartmentSelect'
import RoleSelect from 'components/RoleSelect'
import bloods from 'constants/bloods'
import DateInput from 'components/DateInput'
import Select, { Option } from 'components/Select'
import { get } from 'lodash'
import { setEditUserData, updateUserSubmit, updateUserSubmitSuccess } from 'actions/users'
import Drawer from 'components/Drawer'
import { UsersState } from 'store/reducers/usersReducer'
import User from 'types/User'

interface Props {
  user: User
  onClose(): void
  afterVisibleChange: Function
}

class EditUser extends Component<Props & UsersState & InjectedFormProps> {
  componentWillReceiveProps = nextProps => {
    if (this.props.selectedEditUserData !== nextProps.selectedEditUserData) {
      const { initialize, selectedEditUserData, data } = nextProps
      const user = data.find(e => e.id === selectedEditUserData)
      initialize(user)
    }
  }

  render = () => {
    const {
      submitting,
      handleSubmit,
      pristine,
      invalid,
      user,
      afterVisibleChange,
      selectedEditUserVisible,
      onClose
    } = this.props
    let avatar = get(user, 'avatar')
    return (
      <Drawer
        width={340}
        title="Profil"
        destroyOnClose
        afterVisibleChange={afterVisibleChange}
        placement="right"
        onClose={onClose}
        visible={selectedEditUserVisible}
        footer={
          <>
            <Button onClick={() => setEditUserData()} style={{ marginRight: 8 }}>
              İptal
            </Button>
            <Button onClick={handleSubmit} loading={submitting} disabled={invalid || submitting} type="primary">
              Kaydet
            </Button>
          </>
        }
      >
        <Form>
          <div className="avatar-wrapper">
            <Avatar size={110} border="true" borderColor="white" src={avatar ? avatar.base64 || avatar : ''}>
              <div className={'add-avatar' + (avatar ? ' uploaded' : '')}>
                <Icon type={'plus'} style={{ fontSize: '2rem' }} />
                <Field name="avatar" component={FileInput} />
              </div>
            </Avatar>
          </div>

          <Field label="İsim" name="firstname" component={Input} validate={[required]} type="text" />

          <Field label="Soyisim" name="lastname" component={Input} validate={[required]} type="text" />

          <Field label="E-posta" name="email" component={Input} validate={[required, email]} type="email" />

          <Field label="Telefon" name="phone_number" component={Input} type="tel" />

          <Field label="Acil Durum Telefon" name="emergency_phone_number" component={Input} type="tel" />

          <DepartmentSelect />
          <RoleSelect />

          <Field label="Öğrenci Numarası" name="student_number" component={Input} type="text" />

          <Field name="blood" label="Kan Grubu" placeholder="Seçiniz" component={Select}>
            {bloods.map(blood => (
              <Option key={blood}>{blood}</Option>
            ))}
          </Field>

          <Field label="Doğum Tarihi" name="birthday" component={DateInput} />
        </Form>
      </Drawer>
    )
  }
}

const selector = (state: any) => {
  const value = getFormValues('editUser')(state)
  return {
    user: value,
    ...state.users
  }
}

const form = {
  form: 'editUser',
  onSubmit: updateUserSubmit,
  onSubmitSuccess: updateUserSubmitSuccess
}

export default connect(selector)(reduxForm(form)(EditUser) as any) as any
