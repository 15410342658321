import call from 'utils/call'
import { dispatch } from 'store'
import { AxiosResponse } from 'axios'

export const get = async () => {
  try {
    dispatch({ type: 'LOAD_BOARDS_REQUEST' })
    const response: AxiosResponse = await call.query(
      `
        {
          boards {
            id,
            name,
            roles {
              id,
              name
            }
          }
        }`
    )
    dispatch({ type: 'LOAD_BOARDS_SUCCESS', payload: response.data.boards })
  } catch (error) {
    dispatch({ type: 'LOAD_BOARDS_FAILURE' })
  }
}
