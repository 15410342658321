import { Col, Form, Icon, Row, Button, Typography } from 'antd'
import React, { Component } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import Input from 'components/Input'
import { required } from 'utils/validation'
import { login } from 'actions/auth'

const { Title, Text } = Typography

@(reduxForm<any, any>({
  form: 'login',
  onSubmit: values => login(values),
  onSubmitFail: undefined
}) as any)
class NotAuth extends Component<InjectedFormProps<any> & any> {
  render = () => {
    const { pristine, submitting, handleSubmit, invalid } = this.props

    return (
      <main id="not-auth">
        <Row type="flex" justify="center" align="middle">
          <Col className="login-container">
            <div className="rok-logo" />
            <div className="login-form-container">
              <Title level={4}>Robotik ve Otomasyon Kulübü</Title>
              <Text>YILDIZ TEKNİK ÜNİVERSİTESİ</Text>
              <Form className="login-form">
                <Field
                  name="email"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  component={Input}
                  placeholder={'E-Mail'}
                  type="text"
                  autoFocus
                  style={{ marginTop: 30 }}
                  validate={[required]}
                />

                <Field
                  name="password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  component={Input}
                  placeholder="Password"
                  type="password"
                  validate={[required]}
                />

                <Button
                  htmlType="submit"
                  onClick={handleSubmit}
                  block
                  loading={submitting}
                  disabled={invalid || submitting || pristine}
                  type="primary"
                >
                  Login
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </main>
    )
  }
}

export default NotAuth
