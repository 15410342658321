import Pusher from 'pusher-js'

// Pusher.logToConsole = true

const createPusherInstance = () =>
  new Pusher('b43820407b70f897b3f0', {
    cluster: 'eu',
    encrypted: true,
    forceTLS: true,
    authEndpoint: process.env.REACT_APP_API + '/broadcasting/auth',
    auth: {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('app-token')
      }
    }
  })

export default createPusherInstance
