import React from 'react'
import { Form } from 'antd'

const { Item } = Form

const makeField = (Component: Function) => ({
  input,
  meta,
  hasFeedback,
  groupKey,
  defaultValue,
  options,
  itemLabel,
  children,
  label,
  ...rest
}: any) => {
  const hasError = meta.touched && meta.invalid
  return (
    <Item
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
    >
      {label && <div style={{ lineHeight: '20px' }}>{label}</div>}
      <Component
        {...input}
        {...rest}
        children={children}
        {...(input.value ? { value: input.value } : { value: defaultValue })}
        defaultValue={defaultValue}
      />
    </Item>
  )
}

export default makeField
