import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { get } from 'actions/faculties'
import Select, { Option, OptGroup } from 'components/Select'
import { AppState } from 'store'
import { FacultiesState } from 'store/reducers/facultiesReducer'

class DepartmentSelect extends Component<FacultiesState> {
  componentDidMount = () => {
    const { initialized } = this.props
    if (!initialized) get()
  }

  render = () => {
    const { data, fetched } = this.props
    return (
      <Field
        name="department.id"
        label="Bölüm"
        placeholder="Seçiniz"
        component={Select}
        type="select"
        style={{ width: '100%' }}
        disabled={!fetched}
        loading={!fetched}
      >
        {data.map(
          (faculty: Record<any, any>): React.ReactNode => (
            <OptGroup key={faculty.id} label={faculty.name}>
              {faculty.departments.map((department: Record<any, any>): any => (
                <Option value={department.id} key={department.id}>
                  {department.name}
                </Option>
              ))}
            </OptGroup>
          )
        )}
      </Field>
    )
  }
}

const selector = (state: AppState): FacultiesState => state.faculties

export default connect(selector)(DepartmentSelect)
