import call from '../utils/call'
import { dispatch } from 'store'
import { push } from 'connected-react-router'
import { AxiosResponse } from 'axios'
import { notification } from 'antd'
import { allFields } from 'queries/userQuery'

export const initAuth = async () => {
  const token = localStorage.getItem('app-token')
  call.defaults.headers.common['Authorization'] = 'Bearer ' + token
  if (token) {
    try {
      const response: AxiosResponse = await call.query(
        `
          {
            me {${allFields}}
          }`
      )
      dispatch({ type: 'SET_USER', payload: response.data.me })
    } catch (error) {
      delete call.defaults.headers.common['Authorization']
    }
  } else {
    dispatch({ type: 'REMOVE_USER' })
  }
}

export const login = async ({ email, password }: { email: string; password: string }) => {
  try {
    const response = await call.post('/api/auth/login', {
      email,
      password
    })
    localStorage.setItem('app-token', response.token)
    notification.open({
      type: 'success',
      message: 'Başarıyla giriş yapıldı.'
    })
    initAuth()
  } catch (error) {
    notification.open({
      type: 'error',
      message: 'Kullanıcı adı veya şifreniz hatalı.'
    })
    // throw new SubmissionError(error.validations)
  }
}

export const logout = async () => {
  try {
    await call.post('/api/auth/logout')
    localStorage.setItem('app-token', '')
    dispatch(push('/'))
    dispatch({ type: 'REMOVE_USER' })
  } catch (error) {
    //
  }
}
