import Board from 'types/Board'
import { Reducer, AnyAction } from 'redux'

export interface BoardsState {
  data: Board[]
  initialized: boolean
  loading: boolean
  fetching: boolean
  fetched: boolean
}

const initialState: BoardsState = {
  data: [],
  initialized: false,
  loading: false,
  fetching: false,
  fetched: false
}

const reducer: Reducer<BoardsState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_BOARDS_REQUEST':
      state = { ...state, loading: true, fetching: true }
      break
    case 'LOAD_BOARDS_SUCCESS':
      state = {
        ...state,
        data: action.payload,
        loading: false,
        fetching: false,
        fetched: true,
        initialized: true
      }
      break
    case 'LOAD_BOARDS_FAILURE':
      state = { ...state, data: action.payload }
      break
  }
  return state
}

export default reducer
