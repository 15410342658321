import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer, { AuthState } from './reducers/authReducer'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import usersReducer, { UsersState } from 'store/reducers/usersReducer'
import facultiesReducer, { FacultiesState } from 'store/reducers/facultiesReducer'
import boardsReducer, { BoardsState } from 'store/reducers/boardsReducer'

const logger = createLogger({
  predicate: (getState, action) => !action.type.includes('@@redux-form'),
  level: 'info',
  collapsed: true
})

const middlewares = [thunk, promise, logger]

export const history = createBrowserHistory()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  auth: authReducer,
  users: usersReducer,
  faculties: facultiesReducer,
  boards: boardsReducer
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares, routerMiddleware(history))))

export interface AppState {
  auth: AuthState
  router: RouterState
  boards: BoardsState
  faculties: FacultiesState
  users: UsersState
}

export const dispatch = store.dispatch

export default store
