import axios from 'axios'

interface QueryType {
  query: string
}

interface MutationType {
  mutation: string
}

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
  export interface AxiosInstance {
    query<T = any>(QueryType): AxiosPromise<T>
    mutation<T = any>(MutationType): AxiosPromise<T>
  }
}

const call = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

call.interceptors.response.use(
  response => {
    if (response.data.errors) {
      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data)
  },
  error => {
    return Promise.reject(error.response.data)
  }
)

call.query = query => {
  return call.request({
    method: 'post',
    url: '/graphql',
    data: {
      query: 'query' + query
    }
  })
}

call.mutation = ({ name, data, response }) => {
  const inputs = Object.keys(data)
    .map(element => `${element}: ${Number.isInteger(data[element]) ? data[element] : '"' + data[element] + '"'}`)
    .join(', ')
  return call.request({
    method: 'post',
    url: '/graphql',
    data: {
      query: `
        mutation {
          ${name}(${inputs}) {
            ${response}
          }
        }
      `
    }
  })
}

export default call
