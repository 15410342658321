import React, { Component } from 'react'

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

class FileInput extends Component<any> {
  constructor(props: any) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e: any) {
    const {
      input: { onChange }
    } = this.props
    let file = e.target.files[0]

    getBase64(file).then(data => {
      file.base64 = data
      onChange(file)
    })
  }

  render() {
    return <input type="file" accept=".jpg, .png, .jpeg" onChange={this.onChange} />
  }
}

export default FileInput
