import { Reducer, AnyAction } from 'redux'
import User from 'types/User'

export interface UsersState {
  data: User[]
  initialized: boolean
  loading: boolean
  fetching: boolean
  fetched: boolean

  searchterm: string
  isBoard: boolean

  addUserModal: boolean

  selectedAddUserData: number | null
  selectedAddUserVisible: boolean

  selectedViewUserData: number | null
  selectedViewUserVisible: boolean
  selectedViewMounted: boolean

  selectedEditUserData: number | null
  selectedEditUserVisible: boolean
  selectedEditMounted: boolean
}

const initialState: UsersState = {
  data: [],
  initialized: false,
  loading: false,
  fetching: false,
  fetched: false,

  searchterm: '',
  isBoard: false,

  addUserModal: false,

  selectedAddUserData: null,
  selectedAddUserVisible: false,

  selectedViewUserData: null,
  selectedViewUserVisible: false,
  selectedViewMounted: false,

  selectedEditUserData: null,
  selectedEditUserVisible: false,
  selectedEditMounted: false
}

const reducer: Reducer<UsersState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_USERS_REQUEST':
      state = { ...state, loading: true, fetching: true }
      break
    case 'LOAD_USERS_SUCCESS':
      state = {
        ...state,
        data: action.payload,
        loading: false,
        fetching: false,
        fetched: true,
        initialized: true
      }
      break
    case 'LOAD_USERS_FAILURE':
      state = { ...state, loading: false, fetching: false, fetched: false, initialized: false }
      break

    case 'USER_UPDATED':
      state = {
        ...state,
        data: state.data.map(user => (user.id === action.payload.id ? { ...user, ...action.payload } : user))
      }
      break

    case 'USER_CREATED':
      state = { ...state, data: [action.payload, ...state.data] }
      break

    case 'SHOW_ADD_USER_DATA':
      state = {
        ...state,
        selectedAddUserData: action.payload,
        selectedAddUserVisible: true
      }
      break
    case 'HIDE_ADD_USER_DATA':
      state = {
        ...state,
        selectedAddUserVisible: false
      }
      break
    case 'RESET_ADD_USER_DATA':
      state = {
        ...state,
        selectedViewMounted: false
      }
      break

    case 'SHOW_VIEW_USER_DATA':
      state = {
        ...state,
        selectedViewUserData: action.payload,
        selectedViewUserVisible: true
      }
      break
    case 'HIDE_VIEW_USER_DATA':
      state = {
        ...state,
        selectedViewUserVisible: false
      }
      break
    case 'RESET_VIEW_USER_DATA':
      state = {
        ...state,
        selectedViewMounted: false
      }
      break

    case 'SHOW_EDIT_USER_DATA':
      state = {
        ...state,
        selectedEditUserData: action.payload,
        selectedEditUserVisible: true
      }
      break
    case 'HIDE_EDIT_USER_DATA':
      state = {
        ...state,
        selectedEditUserVisible: false
      }
      break
    case 'RESET_EDIT_USER_DATA':
      state = {
        ...state,
        selectedEditMounted: false
      }
      break

    case 'TOGGLE_ADD_USER_MODAL':
      state = { ...state, addUserModal: action.payload }
      break

    case 'SET_USERS_SEARCHTERM':
      state = { ...state, searchterm: action.payload }
      break
    case 'TOGGLE_BOARD':
      state = { ...state, isBoard: !state.isBoard }
      break
    default:
  }
  return state
}

export default reducer
