import call from 'utils/call'
import { dispatch } from 'store'
import { AxiosResponse } from 'axios'

export const get = async () => {
  try {
    dispatch({ type: 'LOAD_FACULTIES_REQUEST' })
    const response: AxiosResponse = await call.query(
      `
        {
          faculties {
            id,
            name,
            departments {
              id,
              name
            }
          }
        }`
    )
    dispatch({ type: 'LOAD_FACULTIES_SUCCESS', payload: response.data.faculties })
  } catch (error) {
    dispatch({ type: 'LOAD_FACULTIES_FAILURE' })
  }
}
