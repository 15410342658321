/* eslint-disable */
import React, { Component } from 'react'
import { Route, withRouter, Link, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu, Row, Col, Icon, Typography, Button, Card } from 'antd'
import { ThroughProvider } from 'react-through'
import Dashboard from 'routes/Dashboard'
import Users from 'routes/Users'
import Profile from 'routes/Profile'
import UserCard from 'components/UserCard'
import createPusherInstance from 'utils/pusher'
import { logout } from 'actions/auth'

const { Header, Content, Footer, Sider } = Layout
const { Text } = Typography

@(withRouter as any)
@(connect((state: any) => state.auth) as any)
class Auth extends Component<any> {
  menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => logout()}>
        <Icon type="logout" /> Logout
      </Menu.Item>
    </Menu>
  )

  componentDidMount = () => {
    const { dispatch, user } = this.props
    const pusher = createPusherInstance()

    const channel = pusher.subscribe('private-users-channel')
    channel.bind('user.update', message => {
      console.warn(message)
      if (message.data.id === user.id) {
        dispatch({ type: 'SET_USER', payload: message.data })
      }
      dispatch({ type: 'USER_UPDATED', payload: message.data })
    })

    channel.bind('user.create', message => {
      console.warn(message)
      dispatch({ type: 'USER_CREATED', payload: message.data })
    })
  }

  render = () => {
    const { location } = this.props

    return (
      <ThroughProvider>
        <main id="auth">
          <Layout>
            <Sider breakpoint="lg" collapsedWidth="0">
              <div className="logo" />
              <Menu selectedKeys={[location.pathname]} style={{ lineHeight: '64px' }}>
                <Menu.Item key="/">
                  <Link to="/">
                    <Icon type="home" />
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/users">
                  <Link to="/users">
                    <Icon type="user" />
                    <span className="nav-text">Users</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              <Header>
                <div className="header-left">
                  <input placeholder="Arama" />
                </div>
                <div className="header-right">
                  <div>
                    <Icon type="notification" />
                  </div>
                  <div>
                    <UserCard />
                  </div>
                </div>
              </Header>
              <Content style={{ overflow: 'auto' }}>
                <div style={{ minHeight: 360 }}>
                  <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/users" exact component={Users} />
                  </Switch>
                </div>
              </Content>
              <Footer className="footer">
                <Row type="flex" justify="end" className="social">
                  <Col span={4} style={{ textAlign: 'right', fontSize: 20 }}>
                    <a href="#">
                      <Icon theme="filled" type="linkedin" />
                    </a>
                    <a href="#">
                      <Icon theme="filled" type="facebook" />
                    </a>
                    <a href="#">
                      <Icon theme="filled" type="twitter-circle" />
                    </a>
                    <a href="#">
                      <Icon theme="filled" type="instagram" />
                    </a>
                    <a href="#">
                      <Icon theme="filled" type="youtube" />
                    </a>
                  </Col>
                </Row>
              </Footer>
            </Layout>
          </Layout>
        </main>
      </ThroughProvider>
    )
  }
}

export default Auth
