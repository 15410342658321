import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Icon, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { logout } from 'actions/auth'
import { getFullName } from 'selectors/userSelectors'

const menu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/profile">Profil</Link>
    </Menu.Item>
    <Menu.Item key="2" onClick={logout}>
      Çıkış Yap
    </Menu.Item>
  </Menu>
)

const UserCard = ({ user }) => (
  <Dropdown overlay={menu}>
    <div>
      <Avatar style={{ marginRight: 10 }} src={user.avatar} />
      <span>
        {getFullName(user)} <Icon type="down" />
      </span>
    </div>
  </Dropdown>
)

export default (connect((state: any) => state.auth) as any)(UserCard)
