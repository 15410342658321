/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { AutoComplete, Badge, Avatar, Table, Icon, Card, Input, Tooltip, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import Content from 'components/Content'
import ViewUser from 'routes/Users/ViewUser'
import EditUser from 'routes/Users/EditUser'
import AddUser from 'routes/Users/AddUser'
import {
  get,
  setAddUserData,
  setViewUserData,
  setEditUserData,
  resetAddUserData,
  resetViewUserData,
  resetEditUserData,
  toggleAddUserModal
} from 'actions/users'
import classNames from 'classnames'
import ignoreCase from 'ignore-case'
import call from 'utils/call'
import { UsersState } from 'store/reducers/usersReducer'
import { AppState } from 'store'
import { getFullName } from 'selectors/userSelectors'
import { allFields } from 'queries/userQuery'

const { TextArea } = Input

class SearchByPhone extends Component {
  state = {
    data: [],
    value: undefined,
    loading: false
  }

  handleSearch = async value => {
    if (value.length < 5) return
    this.setState({
      loading: true
    })
    try {
      const response = await call.query(
        `
          {
            allUsers (phone_number: "${value}") {${allFields}}
          }`
      )
      this.setState({
        data: response.data.allUsers,
        loading: false
      })
    } catch (error) {
      //
    }
  }

  handleChange = value => {
    console.warn(value)
    this.setState({ value })
  }

  onSelect = phone_number => {
    setAddUserData(this.state.data.find((e: any) => e.phone_number == phone_number))
    toggleAddUserModal(false)
  }

  onEnter = e => {
    setAddUserData({
      phone_number: e.target.value
    })
    toggleAddUserModal(false)
  }

  render = () => {
    const { loading } = this.state
    return (
      <AutoComplete
        autoFocus
        backfill
        dataSource={this.state.data.map((e: any) => e.phone_number)}
        placeholder="Telefon Numarası"
        style={{ width: '100%' }}
        onSelect={this.onSelect}
        onSearch={this.handleSearch}
      >
        <Input
          onPressEnter={this.onEnter}
          suffix={loading ? <Icon type="loading" style={{ color: 'rgba(0,0,0,.25)' }} /> : <span />}
        />
      </AutoComplete>
    )
  }
}

class Users extends Component<any> {
  componentDidMount = () => {
    get()
  }

  columns: any[] = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center'
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text, record) => {
        return (
          <div>
            {record.role.id !== 14 ? (
              <Badge dot>
                <Avatar src={record.avatar} shape="square" icon="user" />
              </Badge>
            ) : (
              <Avatar src={record.avatar} shape="square" icon="user" />
            )}
          </div>
        )
      }
    },
    {
      title: 'İsim',
      dataIndex: 'firstname',
      key: 'firstname',
      sorter: (a, b) => a.fullname.toUpperCase().localeCompare(b.fullname),
      render: (text, record) => getFullName(record)
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.toUpperCase().localeCompare(b.email)
    },
    {
      title: 'Telefon Numarası',
      dataIndex: 'phone_number',
      key: 'phone_number'
    },
    {
      title: 'Eylemler',
      key: 'action',
      render: (text, record) => (
        <div className="actions">
          <Icon onClick={() => setViewUserData(record.id)} type="eye" theme="twoTone" />
          <Icon onClick={() => setEditUserData(record.id)} type="edit" theme="twoTone" />
          <Icon type="delete" onClick={undefined} theme="twoTone" twoToneColor="#eb2f96" />
        </div>
      ),
      width: '20%',
      align: 'center'
    }
  ]

  openModal = type => {
    const { data } = this.props
    const phoneOrMail = type === 'mobile' ? 'phone_number' : 'email'
    Modal.info({
      title: type === 'mobile' ? 'Kayıtlı telefon numaraları' : 'Kayıtlı e-posta adresleri',
      icon: type,
      maskClosable: true,
      okText: 'Tamam',
      content: (
        <div>
          <TextArea
            rows={4}
            value={data
              .filter(user => user[phoneOrMail])
              .map(user => user[phoneOrMail])
              .join(',')}
          />
          <div style={{ fontSize: '.8rem' }}>* Listeye filtreleme uygulanmamıştır!</div>
        </div>
      )
    })
  }

  render = () => {
    const { loading, addUserModal, data, searchterm, isBoard, dispatch } = this.props
    let filteredData = data.filter(
      user =>
        ignoreCase.includes(user.email, searchterm) ||
        (user.fullname && ignoreCase.includes(user.fullname, searchterm)) ||
        (user.phone_number && ignoreCase.includes(user.phone_number, searchterm)) ||
        (user.student_number && ignoreCase.includes(user.student_number, searchterm))
    )
    if (isBoard) {
      filteredData = data.filter(user => user.role.id !== 14)
    }
    return (
      <section id="users">
        <Content>
          <Card className="table-header" bordered={false}>
            <Input
              placeholder="Filtrele"
              value={searchterm}
              onChange={e =>
                dispatch({
                  type: 'SET_USERS_SEARCHTERM',
                  payload: e.target.value
                })
              }
            />
            <Tooltip placement="top" title="Listelenen Kişi Sayısı">
              <Button>{filteredData.length}</Button>
            </Tooltip>
            <Button.Group>
              <Tooltip placement="top" title="Email Listesi">
                <Button icon="mail" onClick={() => this.openModal('mail')} />
              </Tooltip>
              <Tooltip placement="top" title="Telefon Numarası Listesi">
                <Button icon="mobile" onClick={() => this.openModal('mobile')} />
              </Tooltip>
            </Button.Group>
            <Button
              className={classNames({ active: isBoard })}
              icon="team"
              onClick={() => dispatch({ type: 'TOGGLE_BOARD' })}
            >
              Kurul Üyeleri
            </Button>
            <Button type="primary" onClick={() => toggleAddUserModal(true)}>
              <Icon type="plus" /> Üye Ekle
            </Button>
          </Card>
          <Table rowKey="id" loading={loading} dataSource={filteredData} columns={this.columns} />
        </Content>
        <ViewUser onClose={() => setViewUserData()} afterVisibleChange={resetViewUserData} />

        <EditUser onClose={() => setEditUserData()} afterVisibleChange={resetEditUserData} />

        <AddUser onClose={() => setAddUserData()} afterVisibleChange={resetAddUserData} />

        <Modal
          wrapClassName="add-user"
          closable={false}
          destroyOnClose
          onCancel={() => toggleAddUserModal(false)}
          visible={addUserModal}
          maskClosable
          footer={null}
        >
          <SearchByPhone />
        </Modal>
      </section>
    )
  }
}

export default connect((state: AppState): UsersState => state.users)(Users)
