import Faculty from 'types/Faculty'
import { Reducer, AnyAction } from 'redux'

export interface FacultiesState {
  data: Faculty[]
  initialized: boolean
  loading: boolean
  fetching: boolean
  fetched: boolean
}

const initialState: FacultiesState = {
  data: [],
  initialized: false,
  loading: false,
  fetching: false,
  fetched: false
}

const reducer: Reducer<FacultiesState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_FACULTIES_REQUEST':
      state = { ...state, loading: true, fetching: true }
      break
    case 'LOAD_FACULTIES_SUCCESS':
      state = {
        ...state,
        data: action.payload,
        loading: false,
        fetching: false,
        fetched: true,
        initialized: true
      }
      break
    case 'LOAD_FACULTIES_FAILURE':
      state = { ...state, data: action.payload }
      break
  }
  return state
}

export default reducer
